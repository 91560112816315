export default {
    url: {
        myapplications: 'myapplications/',
        propertyleaseapplications: 'propertyleaseapplications/',
        applicationsummary: 'applicationsummary/',
        contactinfo: 'contactinfo/',
        officers: 'officers/',
        incorporationdetails: 'incorporationdetails/',
        soleproprietorinfo: 'soleproprietorinfo/',
        employerinformation: 'employerinformation/',
        proposedterm: 'proposedterm/',
        realestateterms: 'realestateterms/',
        expectedapprovals: 'expectedapprovals/',
        materialsnoise: 'materialsnoise/',
        acknowledgements: 'acknowledgements/',
        finalsubmission: 'finalsubmission/',
        specialeventapplication: 'specialeventapplication/',
        eventinformation: 'eventinformation/',
        applicantsponsorinformation: 'applicantsponsorinformation/',
        agentserviceprocess: 'agentserviceprocess/',
        berthapplication: 'berthapplication/',
        berthrequest: 'berthrequest/',
        vesselinfo: 'vesselinfo/',
        baafinalsubmission: 'baafinalsubmission/',
        eventdescription: 'eventdescription/',
        attachedfiles: 'attachedfiles/',
        attachmentiiib: 'attachmentiiib/',
        publicfunding: 'publicfunding/',
        basiceventdetails: 'basiceventdetails/',
        scopeofwork: 'scopeofwork/',
        proposedoccupancy: 'proposedoccupancy/',
        informationonplans: 'informationonplans/',
        sffdregulatedactivities: 'sffdregulatedactivities/',
        accessibility: 'accessibility/',
        utilities: 'utilities/',
        seafinalsubmission: 'seafinalsubmission/',
        createuser: 'create_user/',
        signin: 'signin/',
        signout: 'signout/',
        session: 'session/',
        csrf: 'csrf/',
        reset_password: 'reset_password/',
        reset: 'reset/',
        domainvalues: 'domainvalues/',
        conceptvalues: 'conceptvalues/',
    },
    id: {
        alterationsNodeId: '3c473edc-d7d6-11ed-93ae-0242ac120006',
        businessTypeNodeId: '3470d31e-ae49-11ed-9fba-0242ac120006',
        expectedApprovalsNodeId: 'fd55b672-e612-11ed-b22b-0242ac120006',
        approvedValueId: '61feb2d1-5795-499e-9d5e-9a2db386a547',
        privatePublicNodeId: '2ed45cdc-af2a-11ed-a103-0242ac120005',
        applicantTypeNodeId: '0b288a8a-af2d-11ed-a526-0242ac120005',
        soleProprietorValueId: '68974f05-1762-4324-a726-1eb4b82fc4c5',
        corportationValueId: 'f4558417-3953-47d1-8fb2-29c73dfa04da',
        completeDescriptionSEANodeId: '5a73283c-d99b-11ed-a1b1-0242ac120006',
        scopeOfWorkNodeId: '7b6d442c-d99c-11ed-923e-0242ac120006',
        plansSignedNodeId: '66f74ffc-d99b-11ed-a1b1-0242ac120006',
        parkingSpacesNodeId: '4cfb3208-d99b-11ed-a1b1-0242ac120006',
        utilityCostsNodeId: '74481ba0-d99b-11ed-a1b1-0242ac120006',
        plaPreliminaryStatusValueId: '3bea9bb8-a501-4d5e-bc6b-cee474d67ffb',
        plaActiveStatusValueId: '495bb19c-4a88-4d47-a2ff-fbb54bd32438',
        seaPreliminaryStatusValueId: 'abac1917-8404-40c7-b096-769af16c5085',
        seaActiveStatusValueId: '60fa9a18-c274-4dc8-a980-61fe673837f3',
        baaPreliminaryStatusValueId: '341b2fa9-a808-4918-9b9a-3c0cd33a3d59',
        baaActiveStatusValueId: 'c2040cbc-fb5a-47fe-aa7e-50f6e5b5a4a0',
    },
};

<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a href="https://sfport.com" target="_blank">
                <img src="../assets/logo.jpg" alt="SFPort Logo">
            </a>

            <a role="button"
                tabindex="0"
                class="navbar-burger"
                @click="showNav = !showNav"
                :class="{ 'is-active': showNav }"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarHeader"
                v-on:keyup.enter="() => showNav = !showNav" >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="navbarHeader" class="navbar-menu" :class="{ 'is-active': showNav }">
            <div class="navbar-start">
                <router-link to="/" class="navbar-item" @click="showNav = !showNav">
                    Lease Applications Home
                </router-link>
                <router-link v-if="isAuthenticated"
                    to="/apply"
                    class="navbar-item is-tab"
                    @click="showNav = !showNav">
                    Apply for a Lease
                </router-link>
                <router-link v-if="isAuthenticated"
                    to="/myapplications"
                    class="navbar-item is-tab"
                    @click="showNav = !showNav">
                    My Applications
                </router-link>
            </div>

            <div class="navbar-end">
                <div v-if="isAuthenticated" class="navbar-item">
                    <div class="welcome-user">
                        Welcome, {{ user.first_name ? user.first_name : user.username }}
                    </div>
                    <button class="button is-medium" @click="signOut">
                        Sign Out
                    </button>
                </div>
                <div v-else class="navbar-item">
                    <router-link to="/signin" class="button is-medium" @click="showNav = !showNav">
                        Sign In
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import api from '../assets/api';

export default {
    name: 'HeaderComponent',
    data() {
        return {
            showNav: false,
        };
    },
    props: ['isAuthenticated', 'user'],
    methods: {
        async signOut() {
            this.showNav = !this.showNav;
            const response = await fetch(`${api.url.signout}?logout=true`);
            const data = await response.json();
            if (data.success) {
                this.$emit('signInEvent');
                this.$router.push({ name: 'signin' });
            } else {
                this.message = data.detail;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    nav {
        background-color: rgb(15, 45, 62);
        color: rgb(208,178, 60);
        height: 60px;
    }

    a {
        color: rgb(208,178, 60);
        text-decoration: none;
    }

    img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        padding: 8px;
    }

    .button {
        width: 160px;
        height: 45px;
        color: rgb(15, 45, 62);
        background-color: rgb(216,178,5);
    }

    .navbar-item {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .welcome-user {
        pointer-events: none;
        font-size: 1rem;
        color: rgb(200, 170, 50);
        padding: 10px;
        font-weight: normal;
    }

    @media screen and (min-width: 1024px) {
        .navbar-item:hover,
        .navbar-item:focus {
            color: rgb(80, 120, 120);
            background-color: rgb(15, 45, 62);
        }
    }
    @media screen and (max-width: 820px) {
        img {
            border-radius: 50%;
            height: 52px;
            width: 52px;
        }
        a:hover {
            color: rgb(216,178,5);
        }
    }
</style>

<template>
    <div>
        <Header
            :isAuthenticated="isAuthenticated"
            :user="user"
            @sign-in-event="signInCallback"
        />
        <main>
            <router-view
                :isAuthenticated="isAuthenticated"
                :user="user"
                @sign-in-event="signInCallback"
            ></router-view>
        </main>
    </div>
</template>

<script>
import Header from './components/Header.vue';
import api from './assets/api';

export default {
    name: 'LeaseApplicationsApp',
    components: {
        Header,
    },
    data() {
        return {
            isAuthenticated: false,
            user: {},
        };
    },

    mounted() {
        this.getSession();
    },
    methods: {
        async getSession() {
            const response = await fetch(api.url.session);
            const data = await response.json();
            this.isAuthenticated = data.isAuthenticated;
            this.user = data.user;
        },

        signInCallback() {
            this.getSession();
        },
    },
};
</script>

import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: '/signin',
        name: 'signin',
        component: () => import('../views/SignInView.vue'),
    },
    {
        path: '/signin/:status',
        name: 'signin_status',
        component: () => import('../views/SignInView.vue'),
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/SignUpView.vue'),
    },
    {
        path: '/signup/:status',
        name: 'signup_status',
        component: () => import('../views/SignUpView.vue'),
    },
    {
        path: '/resetpassword',
        name: 'resetpassword',
        component: () => import('../views/ResetPasswordView.vue'),
    },
    {
        path: '/setpassword',
        name: 'setpassword',
        component: () => import('../views/SetPasswordView.vue'),
    },
    {
        path: '/setpassword/:code',
        name: 'setpassword_code',
        component: () => import('../views/SetPasswordView.vue'),
    },
    {
        path: '/myapplications',
        name: 'myapplications',
        component: () => import('../views/MyApplicationsView.vue'),
    },
    {
        path: '/apply',
        name: 'apply',
        component: () => import('../views/ApplyView.vue'),
    },
    {
        path: '/leaseapplicationtype',
        name: 'leaseapplicationtype',
        component: () => import('../views/LeaseApplication/LeaseApplicationTypeView.vue'),
    },
    {
        path: '/:path/applicationdetails/:resourceId',
        name: 'applicationdetails',
        component: () => import('../views/LeaseApplication/ApplicationDetailsView.vue'),
    },
    {
        path: '/:path/applicationdetails/',
        name: 'applicationdetails_new',
        component: () => import('../views/LeaseApplication/ApplicationDetailsView.vue'),
    },
    {
        path: '/:path/businessinfo/:resourceId',
        name: 'businessinfo',
        component: () => import('../views/LeaseApplication/BusinessDetailsView.vue'),
    },
    {
        path: '/:path/contactinfo/:resourceId',
        name: 'contactinfo',
        component: () => import('../views/LeaseApplication/ContactDetailsView.vue'),
    },
    {
        path: '/:path/officersinfo/:resourceId',
        name: 'officersinfo',
        component: () => import('../views/LeaseApplication/OfficersInfoView.vue'),
    },
    {
        path: '/:path/incorporationdetails/:resourceId',
        name: 'incorporationdetails',
        component: () => import('../views/LeaseApplication/IncorporationDetailsView.vue'),
    },
    {
        path: '/:path/soleproprietorinfo/:resourceId',
        name: 'soleproprietorinfo',
        component: () => import('../views/LeaseApplication/SoleProprietorInfoView.vue'),
    },
    {
        path: '/:path/employerinfo/:resourceId/:tileId',
        name: 'employerinfo',
        component: () => import('../views/LeaseApplication/EmployerInfoView.vue'),
    },
    {
        path: '/:path/proposedtenantfinish/:resourceId',
        name: 'proposedtenantfinish',
        component: () => import('../views/LeaseApplication/ProposedTenantFinishView.vue'),
    },
    {
        path: '/:path/proposedterm/:resourceId',
        name: 'proposedterm',
        component: () => import('../views/LeaseApplication/ProposedTermView.vue'),
    },
    {
        path: '/:path/proposedtermfinish/:resourceId',
        name: 'proposedtermfinish',
        component: () => import('../views/LeaseApplication/ProposedTermFinishView.vue'),
    },
    {
        path: '/:path/proposeduse/:resourceId',
        name: 'proposeduse',
        component: () => import('../views/LeaseApplication/ProposedUseView.vue'),
    },
    {
        path: '/:path/alterationsnotes/:resourceId',
        name: 'alterationsnotes',
        component: () => import('../views/LeaseApplication/AlterationsNotesView.vue'),
    },
    {
        path: '/:path/expectedapprovals/:resourceId',
        name: 'expectedapprovals',
        component: () => import('../views/LeaseApplication/ExpectedApprovalsView.vue'),
    },
    {
        path: '/:path/materialsnoise/:resourceId',
        name: 'materialsnoise',
        component: () => import('../views/LeaseApplication/MaterialsNoiseView.vue'),
    },
    {
        path: '/:path/leasehistory/:resourceId',
        name: 'leasehistory',
        component: () => import('../views/LeaseApplication/LeaseHistoryView.vue'),
    },
    {
        path: '/:path/proposedusefinish/:resourceId',
        name: 'proposedusefinish',
        component: () => import('../views/LeaseApplication/ProposedUseFinishView.vue'),
    },
    {
        path: '/:path/positionacknowledgements/:resourceId',
        name: 'positionacknowledgements',
        component: () => import('../views/LeaseApplication/PositionAcknowledgementsView.vue'),
    },
    {
        path: '/:path/humanrightscommission/:resourceId',
        name: 'humanrightscommission',
        component: () => import('../views/LeaseApplication/HumanRightsCommissionView.vue'),
    },
    {
        path: '/:path/authorizationfinish/:resourceId',
        name: 'authorizationfinish',
        component: () => import('../views/LeaseApplication/AuthorizationFinishView.vue'),
    },
    {
        path: '/berthapplication',
        name: 'berthapplication_new',
        component: () => import('../views/BerthApplication/BerthApplicationView.vue'),
    },
    {
        path: '/berthapplication/:resourceId',
        name: 'berthapplication',
        component: () => import('../views/BerthApplication/BerthApplicationView.vue'),
    },
    {
        path: '/berthrequest/:resourceId',
        name: 'berthrequest',
        component: () => import('../views/BerthApplication/BerthRequestView.vue'),
    },
    {
        path: '/vesselinfo/:resourceId',
        name: 'vesselinfo',
        component: () => import('../views/BerthApplication/VesselInfoView.vue'),
    },
    {
        path: '/berthapplicationfinish/:resourceId',
        name: 'berthapplicationfinish',
        component: () => import('../views/BerthApplication/BerthApplicationFinishView.vue'),
    },
    {
        path: '/eventinformation',
        name: 'eventinformation',
        component: () => import('../views/EventApplication/EventInformationView.vue'),
    },
    {
        path: '/eventinformationresource/:resourceId',
        name: 'eventinformationresource',
        component: () => import('../views/EventApplication/EventInformationView.vue'),
    },
    {
        path: '/applicantsponsorinformation/:resourceId',
        name: 'applicantsponsorinformation',
        component: () => import('../views/EventApplication/ApplicantSponsorInformationView.vue'),
    },
    {
        path: '/agentserviceprocess/:resourceId',
        name: 'agentserviceprocess',
        component: () => import('../views/EventApplication/AgentServiceProcessView.vue'),
    },
    {
        path: '/eventinformationfinish/:resourceId',
        name: 'eventinformationfinish',
        component: () => import('../views/EventApplication/EventInformationFinishView.vue'),
    },
    {
        path: '/eventschedule/:resourceId',
        name: 'eventschedule',
        component: () => import('../views/EventApplication/EventScheduleView.vue'),
    },
    {
        path: '/eventattendance/:resourceId',
        name: 'eventattendance',
        component: () => import('../views/EventApplication/EventAttendanceView.vue'),
    },
    {
        path: '/securityplan/:resourceId',
        name: 'securityplan',
        component: () => import('../views/EventApplication/SecurityPlanView.vue'),
    },
    {
        path: '/trafficplan/:resourceId',
        name: 'trafficplan',
        component: () => import('../views/EventApplication/TrafficPlanView.vue'),
    },
    {
        path: '/cleanupplan/:resourceId',
        name: 'cleanupplan',
        component: () => import('../views/EventApplication/CleanUpPlanView.vue'),
    },
    {
        path: '/toiletlocations/:resourceId',
        name: 'toiletlocations',
        component: () => import('../views/EventApplication/ToiletLocationsView.vue'),
    },
    {
        path: '/sitelocation/:resourceId',
        name: 'sitelocation',
        component: () => import('../views/EventApplication/SiteLocationView.vue'),
    },
    {
        path: '/eventdescriptionfinish/:resourceId',
        name: 'eventdescriptionfinish',
        component: () => import('../views/EventApplication/EventDescriptionFinishView.vue'),
    },
    {
        path: '/publicfunding/:resourceId',
        name: 'publicfunding',
        component: () => import('../views/EventApplication/PublicFundingView.vue'),
    },
    {
        path: '/publicfunding/:resourceId/:attachmentIIIb',
        name: 'publicfundingattachment',
        component: () => import('../views/EventApplication/PublicFundingView.vue'),
    },
    {
        path: '/basiceventdetails/:resourceId/:attachmentIIIb',
        name: 'basiceventdetails',
        component: () => import('../views/EventApplication/BasicEventDetailsView.vue'),
    },
    {
        path: '/scopeofwork/:resourceId/:attachmentIIIb',
        name: 'scopeofwork',
        component: () => import('../views/EventApplication/ScopeOfWorkView.vue'),
    },
    {
        path: '/proposedoccupancy/:resourceId/:attachmentIIIb',
        name: 'proposedoccupancy',
        component: () => import('../views/EventApplication/ProposedOccupancyView.vue'),
    },
    {
        path: '/informationonplans/:resourceId/:attachmentIIIb',
        name: 'informationonplans',
        component: () => import('../views/EventApplication/InformationOnPlansView.vue'),
    },
    {
        path: '/sffdregulatedactivities/:resourceId/:attachmentIIIb',
        name: 'sffdregulatedactivities',
        component: () => import('../views/EventApplication/SFFDRegulatedActivitiesView.vue'),
    },
    {
        path: '/accessibility/:resourceId/:attachmentIIIb',
        name: 'accessibility',
        component: () => import('../views/EventApplication/AccessibilityView.vue'),
    },
    {
        path: '/utilities/:resourceId/:attachmentIIIb',
        name: 'utilities',
        component: () => import('../views/EventApplication/UtilitiesView.vue'),
    },
    {
        path: '/attachmentsfinish/:resourceId/:attachmentIIIb',
        name: 'attachmentsfinish',
        component: () => import('../views/EventApplication/AttachmentsFinishView.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
